import { Component, Prop } from 'vue-property-decorator'
import jsPDF from 'jspdf'
import { GForm } from '@/components/forms/GForm'

@Component
export class GPdf extends GForm {
  @Prop({ type: [Object, Array, Number, String] }) readonly item!: any;
  @Prop({ type: Object, required: false }) readonly options!: any;
  @Prop({ type: Boolean, required: false, default: false }) readonly disabled!: boolean;

  addDate (doc: jsPDF, x: number, y: number, date): number {
    // Agregar texto de fecha primero
    doc.setFontSize(12)
    doc.text(date, x, y)
    return y
  }

  addImage (doc: jsPDF, imageData: string, x: number, y: number): number {
    // Alinear la imagen a la derecha con el tamaño especificado
    const imgWidth = 80 // Ancho de la imagen
    const imgHeight = 8 // Alto de la imagen
    const pageWidth = doc.internal.pageSize.getWidth()
    const xPosition = pageWidth - imgWidth - 10 // 10 es el margen derecho

    // Agregar imagen al PDF
    doc.addImage(imageData, 'PNG', xPosition, y, imgWidth, imgHeight)
    return y + imgHeight
  }

  addSeparator (doc: jsPDF, x: number, y: number): number {
    const pageWidth = doc.internal.pageSize.getWidth()
    doc.setLineWidth(0.3)
    doc.setDrawColor(140) // Color gris claro
    doc.line(x, y, pageWidth - x, y)
    return y + 5 // Retorna la nueva coordenada y después de la línea
  }

  addTextCenter (doc: jsPDF, parts: {
    text: string
    style: string
  }[], x: number, y: number, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5): number {
    const pageWidth = doc.internal.pageSize.getWidth() - 15
    doc.setFontSize(12)

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)
      lines.forEach((line, index) => {
        // Aplicar el margen solo en la primera línea del párrafo
        const currentX = index === 0 ? line.x + addMargin : line.x
        doc.text(line.text, currentX, line.y, { align: 'center' })
        x = line.x + doc.getTextWidth(line.text)
        y = line.y
        if (x > pageWidth) {
          x = 10
          y += 5
        }
        if (addNextLine) {
          x = 10
          y += 5
        }
      })
      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  addTextParts (doc: jsPDF, parts: {
    text: string
    style: string
  }[], x: number, y: number, newLineAfterPart = false, addMargin = 0, addNextLine = false, addFinalMargin = 5): number {
    const pageWidth = doc.internal.pageSize.getWidth() - 15
    doc.setFontSize(12)

    parts.forEach(part => {
      doc.setFont('helvetica', part.style)
      const lines = this.splitText(doc, part.text, part.style, x, y, pageWidth)
      lines.forEach((line, index) => {
        // Aplicar el margen solo en la primera línea del párrafo
        const currentX = index === 0 ? line.x + addMargin : line.x
        doc.text(line.text, currentX, line.y)
        x = line.x + doc.getTextWidth(line.text)
        y = line.y
        if (x > pageWidth) {
          x = 10
          y += 5
        }
        if (addNextLine) {
          x = 10
          y += 5
        }
      })
      if (newLineAfterPart) {
        x = 10
        y += 10
      }
    })

    return y + addFinalMargin
  }

  splitText (doc: jsPDF, text: string, style: string, x: number, y: number, maxWidth: number): {
    text: string
    x: number
    y: number
  }[] {
    const words = text.split(' ')
    const lines: { text: string, x: number, y: number }[] = []
    let currentLine = ''
    let currentX = x

    doc.setFont('helvetica', style)

    words.forEach((word, index) => {
      const testLine = currentLine + (currentLine ? ' ' : '') + word
      const testWidth = doc.getTextWidth(testLine)
      if (currentX + testWidth > maxWidth && currentLine) {
        lines.push({ text: currentLine, x: currentX, y })
        currentLine = word
        y += 5
        currentX = 10
      } else {
        currentLine = testLine
      }

      if (index === words.length - 1) {
        lines.push({ text: currentLine, x: currentX, y })
      }
    })

    return lines
  }

  async getImageData (url: string): Promise<string> {
    const response = await fetch(url)

    const blob = await response.blob()
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }
}
