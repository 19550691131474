
  import { Component, Vue } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import { Reserve, Stock } from '@/entities/sales'
  import dayjs from 'dayjs'
  import { DealAutoAttribute } from '@/entities/public'
  import { Payment } from '@/entities/finance'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Deal } from '@/entities/crm'
  import { Person } from '@/entities/persons'

@Component
  export default class reservePDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  reserve = plainToInstance(Reserve, {})
  stock = plainToInstance(Stock, {})
  autoAttributes = [plainToInstance(DealAutoAttribute, {})]
  order = plainToInstance(Payment, {})
  payments = []
  deal = plainToInstance(Deal, {})
  system = plainToInstance(Person, {})
  mileage = 0
  disabledPdf = false

  async mounted () {
    const { item: { id } } = this

    const check = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { process: { table_name: { _eq: 'reserve' } } },
          { status: { status: { name: { _in: ['pending', 'to_update'] } } } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
    const reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })

    this.disabledPdf = Boolean(check.length) || Boolean(reserve.closingReason)
  }

  async getReserveInfo () {
    const { item: { id } } = this
    this.reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })
    this.stock = await this.fetchData({
      query: { name: 'fetch', model: 'Stock', params: { id: this.reserve.saleOrder.deal.stock.id } },
      force: true,
    })
    this.autoAttributes = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: { id_deal: { _eq: this.reserve.saleOrder.deal.id } },
      force: true,
    })
    this.system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
      force: true,
    })

    const mileage = await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: { id_auto: { _eq: this.item.saleOrder.deal.auto.id }, component: { slug: { _eq: 'mileage' } } },
    })

    this.mileage = mileage.sort((prev, next) => {
      return next.value - prev.value
    })[0].value

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    const order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_process: { _eq: process?.[0].id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    this.order = order[0]
    this.payments = order

    this.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.reserve.saleOrder.deal.id } },
      force: true,
    })

    this.stock.transferCost = await this.calculateTransfer(this.stock.id)
  }

  async generatePDF () {
    this.loading = true

    await this.getReserveInfo()
    const pdfContent = this.$refs.pdfContent
    const element = pdfContent instanceof HTMLElement ? pdfContent : (pdfContent as Vue).$el as HTMLElement

    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a4')

    // Coordenadas iniciales
    const x = 10
    const y = 20

    doc.setLineWidth(400)

    // Llenar el PDF con el contenido
    await this.page1(doc, y, x)

    // Convertir el contenido del HTML a texto en el PDF
    this.addHtmlContent(doc, element, x, y)
  }

  async page1 (doc, y, x) {
    const date = dayjs().format('DD/MM/YYYY')
    // Descargar la imagen y convertirla en un formato que jsPDF pueda usar
    const imageData = require('@/assets/companyLogo/logo.png')

    y = this.addDate(doc, x, y, date)
    y = this.addImage(doc, imageData, x, y)
    y = this.addHeader(doc, x, y)
    y = this.addDeclaration(doc, x, y)
    y += 5
    const declarationParts = [
      { text: 'Declaro haber leído y acepto las condiciones indicadas en el presente documento', style: 'normal' },
    ]

    y = this.addTextParts(doc, declarationParts, x, y)
    y += 5

    if (this.reserve.saleOrder.deal.lead.client.email?.personal) {
      const emailText = [
        {
          text: 'Para mayor transparencia, comodidad y respaldo, te enviaremos una copia de la presente garantía al ',
          style: 'normal',
        },
        { text: ` correo ${this.reserve.saleOrder.deal.lead.client.email.personal}`, style: 'bold' },
      ]

      y = this.addTextParts(doc, emailText, x, y)
      y += 5
    }

    const additionalContentParts = [
      { text: 'Le recuerdo que está siendo atendido por ', style: 'normal' },
      { text: ` ${this.reserve.saleOrder.deal.lead.executive.name} `, style: 'bold' },
    ]

    y = this.addTextParts(doc, additionalContentParts, x, y)

    const phoneParts = [
      { text: ' y se puede contactar al ', style: 'normal' },
      { text: ` ${this.reserve.saleOrder.deal.lead.executive.secondaryPhone.work} `, style: 'bold' },
      { text: ' o con atención al cliente ', style: 'normal' },
      { text: ` ${this.system[0].secondaryPhone.work}`, style: 'bold' },
    ]

    y = this.addTextParts(doc, phoneParts, x, y)
    y += 20

    const signatureParts = [
      { text: 'Firmo y acepto las condiciones de reserva', style: 'normal' },
      {
        text: ` ${this.reserve.saleOrder.deal.lead.client.shortName}, ${this.reserve.saleOrder.deal.lead.client.uid}`,
        style: 'bold',
      },
    ]

    y = this.addTextCenter(doc, signatureParts, x, y, true, 95)

    return y
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`reserva ${this.reserve.saleOrder.deal.lead.client.shortName} ${this.reserve.saleOrder.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  addDeclaration (doc: jsPDF, x: number, y: number): number {
    y += 5

    let paidAmount = 0

    this.payments.forEach(payment => {
      paidAmount += payment.amount
    })

    const declarationParts = [
      { text: 'Por la presente, yo ', style: 'normal' },
      { text: ` ${this.reserve.saleOrder.deal.lead.client.shortName}`, style: 'bold' },
      {
        text: `, RUT ${this.reserve.saleOrder.deal.lead.client.uid} declaro mi intención de comprar el vehículo `,
        style: 'normal',
      },
      { text: `${this.stock.auto.nameWithOutPPU}`, style: 'bold' },
      { text: ', patente ', style: 'normal' },
      { text: `${this.stock.auto.ppu} `, style: 'bold' },
      { text: ` con ${fixAmountFormatted(this.mileage)} kms, `, style: 'normal' },
      { text: `con un abono total al día ${dayjs().format('DD/MM/YYYY')} de `, style: 'normal' },
      { text: `${fixPrice(paidAmount)}`, style: 'bold' },
      { text: ', que se descontará al valor total a pagar.', style: 'normal' },
    ]

    y = this.addTextParts(doc, declarationParts, x, y) + 5

    const salePlusTransfer = this.deal.price + this.stock.transferCost
    const remainingCost = salePlusTransfer - paidAmount

    const additionalParts = [
      { text: `Precio acordado de venta ${fixPrice(this.deal.price)}`, style: 'normal' },
      { text: `Costo de traspaso es de ${fixPrice(this.stock.transferCost)}`, style: 'normal' },
      { text: `Precio de venta + traspaso ${fixPrice(salePlusTransfer)}`, style: 'normal' },
      { text: `Saldo por cubrir ${fixPrice(remainingCost)}`, style: 'normal' },
    ]

    y = this.addTextParts(doc, additionalParts, x, y, true)

    const thirdDeclarationParts = [
      {
        text: `Me comprometo a concretar la compra antes del día ${this.reserve.expiration.format('DD/MM/YYYY')}`,
        style: 'normal',
      },
    ]

    y = this.addTextParts(doc, thirdDeclarationParts, x, y, true)

    const bulletParts = [
      {
        text: '• Renuncio a la devolución del pago realizado al no concretar la compra en la fecha indicada.',
        style: 'normal',
      },
      {
        text: '• Petersen se compromete a no vender el vehículo a nadie más mientras esté vigente la reserva.',
        style: 'normal',
      },
      { text: '• Petersen me reembolsará íntegramente los abonos realizados en caso de que:', style: 'normal' },
    ]

    y = this.addTextParts(doc, bulletParts, x, y, true, 2, false, 0)

    const numberParts = [
      { text: '1. NO aprueben mi solicitud de financiamiento.', style: 'normal' },
      { text: '2. NO mantengan el valor ofrecido por mi vehículo.', style: 'normal' },
      {
        text: `3. El ${this.stock.auto.nameWithOutPPU}, patente ${this.stock.auto.ppu} NO se encuentre en las condiciones informadas.`,
        style: 'normal',
      },
    ]
    y = this.addTextParts(doc, numberParts, x, y, false, 10, true, 0)
    return y
  }

  addHeader (doc: jsPDF, x: number, y: number): number {
    doc.setFontSize(12)
    doc.text('PETERSEN ltda', x, y)
    y += 10
    doc.setFontSize(15)
    doc.text('FORMALIZACIÓN DE RESERVA', x, y)

    y += 10
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('Firmantes:', x, y)
    doc.setFont('helvetica', 'normal')
    doc.text(` ${this.reserve.saleOrder.deal.lead.client.shortName}`, x + 22, y)
    y += 5
    y = this.addSeparator(doc, x, y)
    return y
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
